import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var _this5 = this;
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import SearchSelect from '../../components/common/Search';
import { courseSearchList } from '../../assets/js/data';
import { qn_token_get, uploadFile } from '../../api/qiniuApI';
export default {
  name: "CourseList",
  data: function data() {
    return {
      courseTypeList: [],
      //课程类型
      centerDialogVisible: false,
      //是否显示删除课程的弹框
      courseId: 0,
      //当前的课程ID
      tableKey: 1,
      //表格的key
      drawer: false,
      //是否显示弹框
      num: 1,
      //排列顺序
      isEdit: false,
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      courseSearchList: courseSearchList,
      state: true,
      //课程状态
      //getUserList接口返回表格数据
      tableData: [],
      total: 0,
      //页数总数
      currentPage: parseInt(this.$route.query.currentPage ? this.$route.query.currentPage : 1),
      // 当前页数
      pageSize: parseInt(this.$route.query.pageSize ? this.$route.query.pageSize : 10),
      //每页个数
      imageUrl: "",
      //课程图标
      courseData: {
        //添加、修改课程数据
        userId: this.Ls.getParseToken('nof_userInfo').userId,
        roleId: this.Ls.getParseToken('nof_userInfo').roleId,
        name: "",
        alias: "",
        //课程别名
        code: "",
        logo: "",
        //base文件编码
        provider: "",
        //提供商名称
        description: "",
        //课程描述
        rank: 1,
        //排序
        state: 1,
        //状态
        courseTypes: [],
        //课程描述
        isGuide: 0 //是否引流课程
      }
    };
  },

  watch: {
    '$route': 'initData'
  },
  components: {
    SearchSelect: SearchSelect
  },
  created: function created() {
    //获取数据
    this.initData();
    //获取全部课程类型
    this.structureTypeGetAll();
  },
  methods: {
    //是否显示目标体系
    isHideMB: function isHideMB(arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].structureTypeName == "目标体系") {
          return true;
        }
      }
      return false;
    },
    validateArray: function validateArray(rule, value, callback) {
      // console.log(88888888,rule,value,callback)
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error('请选择课程类型'));
      }
    },
    //课程类型改变
    checkedItem: function checkedItem(value) {
      console.log(value, this.courseTypeList);
    },
    getCheckedCourseType: function getCheckedCourseType() {
      var arr = [];
      for (var i = 0; i < this.courseTypeList.length; i++) {
        if (this.courseTypeList[i].checked == true) {
          arr.push(this.courseTypeList[i].id);
        }
      }
    },
    //获取全部课程类型
    structureTypeGetAll: function structureTypeGetAll() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'structureTypeGetAll',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId
        },
        sucFn: function sucFn(res) {
          _this2.courseTypeList = res.data;
        }
      });
    },
    //取消抽屉
    cancelDrawer: function cancelDrawer() {
      this.$refs.form.resetFields();
      this.$refs.form.clearValidate();
      this.courseData.courseTypes = [];
      this.drawer = false;
    },
    //删除课程
    deleteCourse: function deleteCourse() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: 'deleteCourse',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: this.courseId
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this3.centerDialogVisible = false;
            _this3.$toast.success(res.message);
            _this3.initData();
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    initData: function initData() {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'getCourseList',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10,
          pageIndex: this.$route.query.currentPage ? this.$route.query.currentPage : 1
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.tableData = res.data.data;
            _this4.total = res.data.total;
            _this4.tableKey++;
            //抽屉恢复初始化
            _this4.cancelDrawer();
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    //上传图片转base64
    handleAvatarSuccess: function handleAvatarSuccess(response, uploadFile) {
      _this5.imageUrl = URL.createObjectURL(uploadFile.raw);
    },
    //编辑课程
    edit: function edit(row) {
      var _this6 = this;
      this.drawer = true;
      this.isEdit = true;
      setTimeout(function () {
        _this6.courseData = _objectSpread({}, row);
        _this6.courseData.courseTypes = [];
        for (var i = 0; i < row.coursetype.length; i++) {
          _this6.courseData.courseTypes.push(row.coursetype[i].structureTypeId);
        }
        // this.courseData.courseTypes=row.coursetype;
        delete _this6.courseData.coursetype;
        // console.log("编辑的数据",this.courseData)
      }, 0);
    },
    //添加课程确定
    confirmClick: function confirmClick(formName) {
      var _this = this;
      if (this.file) {
        uploadFile({
          folderName: "logo",
          complete: function complete(res, url) {
            _this.courseData.logo = url;
            _this.addEditCourse(formName);
          }
        }, this.file);
      } else {
        this.addEditCourse(formName);
      }
    },
    //添加修改课程
    addEditCourse: function addEditCourse(formName) {
      var _this7 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this7.isEdit) {
            //修改
            _this7.courseData.roleId = _this7.userInfo.roleId;
            _this7.service.axiosUtils({
              requestName: 'editCourse',
              data: _this7.courseData,
              sucFn: function sucFn(res) {
                if (res.status == 1) {
                  _this7.drawer = false;
                  _this7.$toast.success(res.message);
                  _this7.initData();
                } else {
                  _this7.$toast.error(res.message);
                }
              }
            });
          } else {
            //添加
            _this7.service.axiosUtils({
              requestName: 'addCourse',
              data: _this7.courseData,
              sucFn: function sucFn(res) {
                if (res.status == 1) {
                  _this7.drawer = false;
                  _this7.$toast.success(res.message);
                  _this7.initData();
                } else {
                  _this7.$toast.error(res.message);
                }
              }
            });
          }
        }
      });
    },
    //修改课程图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        // console.log(e)
        _this.courseData.logo = this.result;
      };
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      // 获取最大页数
      var pageMax = Math.ceil(this.total / this.pageSize);
      // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
      if (this.currentPage > pageMax) {
        this.currentPage = pageMax;
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: page
        }
      });
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          currentPage: this.currentPage,
          pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 10
        }
      });
    },
    //修改课程状态
    changeCourseState: function changeCourseState(value) {
      var _this8 = this;
      this.courseData.roleId = this.userInfo.roleId;
      var postData = {
        userId: this.userInfo.userId,
        roleId: this.userInfo.roleId,
        id: value.id
      };
      if (value.state == 1) {
        postData.state = -1;
      } else {
        postData.state = 1;
      }
      this.service.axiosUtils({
        requestName: 'changeCourseState',
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this8.$toast.success(res.message);
          }
          _this8.initData();
        }
      });
    },
    nextPage: function nextPage(v, t) {
      switch (parseInt(t)) {
        case 2:
          //结构管理
          localStorage.setItem("course", JSON.stringify(v));
          this.$router.push({
            path: "/course/structure"
          });
          break;
        case 3:
          //3资源管理
          // localStorage.setItem("course",JSON.stringify(v))
          sessionStorage.setItem("course-" + v.id, JSON.stringify(v));
          this.$router.push({
            path: "/course/edit/courseResource",
            query: {
              courseId: v.id
            }
          });
          break;
        case 4:
          //版本管理
          sessionStorage.setItem("course-" + v.id, JSON.stringify(v));
          this.$router.push({
            path: "/course/version",
            query: {
              courseId: v.id
            }
          });
          break;
        case 5:
          //目标体系管理
          sessionStorage.setItem("course-" + v.id, JSON.stringify(v));
          this.$router.push({
            path: "/course/objectiveIndex",
            query: {
              courseName: v.name,
              courseId: v.id,
              stateCourse: v.state,
              code: v.code
            }
          });
          break;
      }
    }
  }
};